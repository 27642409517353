// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guide-detail-guide-detail-js": () => import("./../../../src/pages/GuideDetail/GuideDetail.js" /* webpackChunkName: "component---src-pages-guide-detail-guide-detail-js" */),
  "component---src-pages-home-home-js": () => import("./../../../src/pages/Home/Home.js" /* webpackChunkName: "component---src-pages-home-home-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-product-details-product-details-js": () => import("./../../../src/pages/ProductDetails/ProductDetails.js" /* webpackChunkName: "component---src-pages-product-details-product-details-js" */),
  "component---src-pages-property-for-sale-index-js": () => import("./../../../src/pages/property/for-sale/index.js" /* webpackChunkName: "component---src-pages-property-for-sale-index-js" */),
  "component---src-pages-property-to-rent-index-js": () => import("./../../../src/pages/property/to-rent/index.js" /* webpackChunkName: "component---src-pages-property-to-rent-index-js" */),
  "component---src-pages-review-form-js": () => import("./../../../src/pages/review-form.js" /* webpackChunkName: "component---src-pages-review-form-js" */),
  "component---src-pages-search-result-search-result-js": () => import("./../../../src/pages/SearchResult/SearchResult.js" /* webpackChunkName: "component---src-pages-search-result-search-result-js" */),
  "component---src-pages-static-page-static-page-js": () => import("./../../../src/pages/StaticPage/StaticPage.js" /* webpackChunkName: "component---src-pages-static-page-static-page-js" */),
  "component---src-pages-team-details-team-details-js": () => import("./../../../src/pages/TeamDetails/TeamDetails.js" /* webpackChunkName: "component---src-pages-team-details-team-details-js" */),
  "component---src-pages-valuation-one-valuation-one-js": () => import("./../../../src/pages/ValuationOne/ValuationOne.js" /* webpackChunkName: "component---src-pages-valuation-one-valuation-one-js" */),
  "component---src-pages-valuation-two-valuation-two-js": () => import("./../../../src/pages/ValuationTwo/ValuationTwo.js" /* webpackChunkName: "component---src-pages-valuation-two-valuation-two-js" */),
  "component---src-templates-area-guide-template-js": () => import("./../../../src/templates/area-guide-template.js" /* webpackChunkName: "component---src-templates-area-guide-template-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-office-template-js": () => import("./../../../src/templates/office-template.js" /* webpackChunkName: "component---src-templates-office-template-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */),
  "component---src-templates-video-template-js": () => import("./../../../src/templates/video-template.js" /* webpackChunkName: "component---src-templates-video-template-js" */)
}

